import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import chroma from 'chroma-js'
import { Slider as SoftSlider } from 'react-soft-slider'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { config } from 'react-spring'
import Img from 'gatsby-image'
import NoSsr from '@material-ui/core/NoSsr'
const style = { width: 300, height: '100%', margin: '0 10px' }

import ShadowWrapper from '../ShadowWrapper'

const offset = 30

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    //border: 'solid 10px' + theme.palette.primary.main,
    background: '#ecf7f7',
    //background: theme.palette.secondary.main,
    zIndex: 3,
  },
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  background: {
    position: 'absolute',
    width: 600,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: '8px',
    //borderRadius: '8px',
    zIndex: -1,
    outline: 'solid 10px #2D4F59',
    //boxShadow: '10px 10px 48px 0px rgba(37,53,60,0.2)',
  },
  image: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    position: 'relative',
    height: 337.5,
    width: 600,
    willChange: 'transform',
    opacity: 0.2,
    boxShadow: '10px 10px 48px 0px rgba(37,53,60,0.2)',
  },
  imageActive: {
    opacity: 1,

    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  imageWrapper: {},
  button: {
    fontSize: '0.8em',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const Slider = ({ className, children, id, bg, variant }) => {
  const classes = useStyles()
  const [index, setIndex] = React.useState(3)
  const timeout = useRef()
  const { t, i18n } = useTranslation()
  const handleChangeImageIndex = useCallback(i => {
    setIndex(i)
  })

  const handlePreviousImageIndex = useCallback(i => {
    stopAutoplay()
    setIndex((index + nbSlides - 1) % nbSlides)
    startAutoplay()
  })

  const handleNextImageIndex = useCallback(i => {
    stopAutoplay()
    setIndex((index + 1) % nbSlides)
    startAutoplay()
  })

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "gallery" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  console.log('data', data)
  const nbSlides = data.allFile.edges.length

  const startAutoplay = useCallback(() => {
    timeout.current = setInterval(() => setIndex((index + 1) % nbSlides), 3000)
  }, [index, nbSlides, setIndex])

  const stopAutoplay = useCallback(() => void clearTimeout(timeout.current), [])

  useEffect(() => {
    startAutoplay()
    return stopAutoplay
  }, [startAutoplay, stopAutoplay])

  const Slides = data.allFile.edges.map((edge, i) => {
    return (
      <Img
        fluid={edge.node.childImageSharp.fluid}
        className={clsx(classes.image, index === i && classes.imageActive)}
        onClick={() => {
          handleChangeImageIndex(i)
        }}
        draggable={false}
        key={i}
        objectFit="cover"
        objectPosition="50% 50%"
      />
    )
  })

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.background} />
        <NoSsr>
          <SoftSlider
            index={index}
            onIndexChange={setIndex}
            trail={true}
            draggedScale={0.8}
            draggedSpring={'wobbly'}
            trailingSpring={'wobbly'}
          >
            {Slides}
          </SoftSlider>
        </NoSsr>
      </div>
      <Grid container justify="center">
        <Button
          className={classes.button}
          variant="contained"
          onClick={handlePreviousImageIndex}
        >
          {t('home:section2.buttonPrev')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleNextImageIndex}
        >
          {t('home:section2.buttonNext')}
        </Button>
      </Grid>
    </React.Fragment>
  )
}

export default Slider
