import React from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import chroma from 'chroma-js'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Section from '../Section'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: '60vh',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
  },
  rootSecondary: {
    minHeight: '10vh',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    background: chroma(theme.palette.primary.main)
      .alpha(0.8)
      .css(),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    zIndex: 2,
    background: chroma(theme.palette.tertiary.main)
      .alpha(0.8)
      .css(),
    borderRadius: '20px',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      alignItems: 'center',
      maxWidth: '96%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  contentSecondary: {
    background: 'transparent',
    borderRadius: '20px',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  overlay: {
    background: theme.palette.primary.main,
    position: 'absolute !important',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: -2,
  },
  background: {
    position: 'absolute !important',
    top: '10%',
    right: 0,
    left: '40%',
    bottom: '10%',
    zIndex: -1,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
    //borderTop: 'solid 6px ' + theme.palette.primary.main,
  },
  backgroundSecondary: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  logo: {
    display: 'block',
    width: '100%',
  },
  waves: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  title: {
    fontWeight: 900,
    marginBottom: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  titleSecondary: {
    position: 'relative',
    color: theme.palette.tertiary.main,
    marginTop: 0,
    '&::after': {
      content: '" "',
      bottom: -30,
      position: 'absolute',
      display: 'block',
      height: '10px',
      width: '200px',
      borderRadius: '4px',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  subtitleSecondary: {
    color: '#fff',
  },
}))

const Hero = ({ title, subtitle, children, variant }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.root,
          variant === 'secondary' && classes.rootSecondary
        )}
      >
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={clsx(
            classes.background,
            variant === 'secondary' && classes.backgroundSecondary
          )}
          style={{
            margin: '0 auto', // Used to center the image
          }}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'right center',
          }}
        />
        <Container
          className={clsx(
            classes.content,
            variant === 'secondary' && classes.contentSecondary
          )}
          style={{
            textTransform: variant === 'primary' ? 'none' : 'uppercase',
          }}
        >
          <Typography
            variant={variant === 'secondary' ? 'h4' : 'h1'}
            component="h1"
            className={clsx(
              classes.title,
              variant === 'secondary' && classes.titleSecondary
            )}
          >
            {title}
          </Typography>
          <Typography
            variant={variant === 'secondary' ? 'h5' : 'h2'}
            component="h2"
            className={clsx(
              classes.subtitle,
              variant === 'secondary' && classes.subtitleSecondary
            )}
          >
            {subtitle}
          </Typography>
          {children}
        </Container>
      </div>
    </React.Fragment>
  )
}

Hero.defaultProps = {
  variant: 'primary',
}

export default Hero
