const DEFAULT_LANG = 'fr'
const LANGS = ['fr', 'en']
const NAMESPACES = [
  'home',
  'menu',
  'footer',
  'cabinet',
  'dentisterie',
  'implantologie',
  'protheses',
  'urgences',
  'links',
]

module.exports = {
  DEFAULT_LANG,
  LANGS,
  NAMESPACES,
}
