import React from 'react'
import clsx from 'clsx'
import chroma from 'chroma-js'
import Img from 'gatsby-image'
import { Link as ScrollLink, Element } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslation } from 'react-i18next'
import { FiPhoneOutgoing } from 'react-icons/fi'

import Link from '../Link'
import Section from '../Section'
import Heading from '../Heading'
import Map from '../Map'
import ShadowWrapper from '../ShadowWrapper'

import pagesInfos from '../../config/i18n/pagesInfos'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '3em',
  },
  titleAddress: {
    color: theme.palette.secondary.main,
  },
  padd: {
    padding: '2em',
  },
  item: {
    color: '#fff',
  },
  marginLeft: {
    marginLeft: '1.6em',
  },
  icon: {
    color: '#fff',
  },
  button: {
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  scrollLink: {
    cursor: 'pointer',
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  iconSignature: {
    color: '#FC0D1B',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  waves1: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  waves2: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 10,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  waves3: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 20,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  spacingContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
}))

const Footer = ({ title, subtitle, children, variant }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      payments: file(relativePath: { eq: "home/bancontact.png" }) {
        childImageSharp {
          fixed(width: 154) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Element id="contact" name="contact">
        <Section bg="#ecf7f7">
          <Heading title="Contact" variant="primary" />
          <Grid container spacing={3} justify="space-between">
            <Grid item sm={6} xs={12}>
              <ShadowWrapper>
                <Map />
              </ShadowWrapper>
            </Grid>
            <Grid
              item
              container
              spacing={3}
              sm={5}
              xs={12}
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h6" color="primary">
                  <strong>{t('footer:section1.title')}</strong>
                </Typography>
                <div className={classes.spacingContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    href="tel:02 762 04 81"
                    title="Appeler maintenant"
                    startIcon={<FiPhoneOutgoing />}
                  >
                    <strong>{t('footer:section1.button')}</strong>
                  </Button>
                </div>

                <Typography variant="h6" color="primary" gutterBottom>
                  <strong>{t('footer:section3.title')}</strong>
                </Typography>
                <Typography variant="body2">Rue au Bois 406</Typography>
                <Typography variant="body2">
                  1150 Woluwe-Saint-Pierre
                </Typography>
                <Typography variant="body2">Belgique</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" color="primary" gutterBottom>
                  <strong>{t('footer:section2.title')}</strong>
                </Typography>
                <List dense disablePadding>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span>
                          <strong>{t('footer:section2.days.monday')}</strong>.:
                          08:30 – 18:30
                        </span>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span>
                          <strong>{t('footer:section2.days.tuesday')}</strong>.:
                          08:30 – 18:30
                        </span>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span>
                          <strong>{t('footer:section2.days.wednesday')}</strong>
                          .: 08:30 – 12:30
                        </span>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span>
                          <strong>{t('footer:section2.days.thursday')}</strong>
                          .: 08:30 – 18:30
                        </span>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <span>
                          <strong>{t('footer:section2.days.friday')}</strong>.:
                          08:30 – 17:00
                        </span>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <strong>
                          {t('footer:section2.days.saturday')}.:{' '}
                          {t('footer:section2.closed')}
                        </strong>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <strong>
                          {t('footer:section2.days.sunday')}.:{' '}
                          {t('footer:section2.closed')}
                        </strong>
                      }
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                {t('footer:section4.paragraphs1', {
                  returnObjects: true,
                }).map(paragraph => (
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                    key={paragraph}
                  >
                    {paragraph}
                  </Typography>
                ))}

                <div className={classes.spacingContainer}>
                  <Img fixed={data.payments.childImageSharp.fixed} />
                </div>
                {t('footer:section4.paragraphs2', {
                  returnObjects: true,
                }).map(paragraph => (
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                    key={paragraph}
                  >
                    {paragraph}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Section>
      </Element>
      <footer style={{ position: 'relative', zIndex: 0 }} id="contact">
        <Section
          bg={chroma(theme.palette.primary.main)
            .darken(1)
            .css()}
        >
          <Grid container>{/*sd**/}
            <Grid item sm={6} xs={12}>
              <p style={{color:"white"}}>
                Numéro Inami : 3/03118/07/001<br></br>
                BCE : 0845.071.027<br></br>
                Non conventionné<br></br>
              </p>
              
            </Grid>
          </Grid>
        </Section>
      </footer>
    </React.Fragment>
  )
}

Footer.defaultProps = {
  variant: 'secondary',
}

export default Footer
