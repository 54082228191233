const NAMESPACES = [
  'home',
  'menu',
  'footer',
  'projetPedagogique',
  'equipe',
  'cycleHiver20192020',
  'cyclePrintemps2020',
  'stagesDeTennis20192020',
]

module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: NAMESPACES,
    path: {
      fr: '/',
      en: '/en',
    },
  },
  /************************************************ 
    Pages
  *************************************************/
  ComponentCabinet: {
    id: 'ComponentCabinet',
    namespace: NAMESPACES,
    path: {
      fr: '/cabinet',
      en: '/en/surgery',
    },
  },
  ComponentDentisterieGenerale: {
    id: 'ComponentDentisterieGenerale',
    path: {
      fr: '/dentisterie-generale',
      en: '/en/general-dentistry',
    },
  },
  ComponentImplantologie: {
    id: 'ComponentImplantologie',
    namespace: NAMESPACES,
    path: {
      fr: '/implantologie',
      en: '/en/implantology',
    },
  },
  ComponentLiens: {
    id: 'ComponentLiens',
    namespace: NAMESPACES,
    path: {
      fr: '/liens',
      en: '/en/links',
    },
  },
  ComponentProtheses: {
    id: 'ComponentProtheses',
    namespace: NAMESPACES,
    path: {
      fr: '/protheses',
      en: '/en/protheses',
    },
  },
  ComponentUrgences: {
    id: 'ComponentUrgences',
    namespace: NAMESPACES,
    path: {
      fr: '/urgences',
      en: '/en/emergencies',
    },
  },
}
