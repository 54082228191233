import React, { Component } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

const DefaultLink = ({
  page,
  children,
  className,
  style,
  query,
  lang,
  replace,
}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const currentLanguge = i18n.language

  if (!page) {
    return <span />
  }
  let href = page.path[lang || currentLanguge]

  if (query) {
    href += query
  }

  return (
    <Link
      to={href}
      className={clsx(classes.link, className)}
      style={style}
      replace={replace}
    >
      {children}
    </Link>
  )
}

DefaultLink.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  query: PropTypes.string,
  lang: PropTypes.string,
  replace: PropTypes.bool,
}

DefaultLink.defaultProps = {
  className: undefined,
  style: undefined,
  query: undefined,
  lang: undefined,
  replace: false,
}

export default DefaultLink
