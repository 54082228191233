import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import Img from 'react-image'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const images = [
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/56976939_414061389142107_2809380804583489536_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/56980809_414061349142111_1304464391944536064_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/56997632_414061485808764_5955734014382833664_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57052386_414061382475441_999153557921857536_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57154222_414061452475434_1990027677322444800_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57154446_414061309142115_7134037659017543680_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57262703_414061455808767_1569219189820882944_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57277855_415800352301544_2500266244444258304_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57289471_414061312475448_4951530936425512960_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57387286_415800328968213_1529123522629074944_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57419003_415800268968219_7868265904566435840_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57453651_414061342475445_5850468291557982208_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57486161_415800295634883_7440891052019417088_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57568392_415800242301555_3703801310114480128_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/57577026_417290818819164_2689809314494808064_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/58375108_417290895485823_389890761694380032_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/58376255_415800235634889_5689564072027095040_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/58377273_417290825485830_6596929368470061056_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/58462126_417290892152490_3299924773399691264_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61373948_436086850272894_8587406230652190720_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61485725_436086986939547_8458958975897960448_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61567026_436086950272884_6184775025321574400_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61627200_436086896939556_6500270210350579712_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61760067_436086993606213_4992041454508441600_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61815335_436086900272889_4030650125226344448_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/61931952_436086943606218_1602475821673480192_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/62074183_436086836939562_2595512354028388352_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65041958_448451369036442_4586525477237161984_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65562276_455179728363606_591286635811831808_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65780647_455179695030276_8054412200946696192_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65899803_455179868363592_5591488631261888512_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65941513_455179795030266_6092554217686302720_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65972312_455179871696925_7218313515889590272_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65984824_455179701696942_3015851534748483584_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/65993889_455179785030267_2558606610586927104_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/66298445_455179748363604_1756348771718922240_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/66361408_457145288167050_9101118296740069376_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/68666654_478639039351008_4314532740344053760_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/68738606_478639009351011_7468068196319232000_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/68742386_478638976017681_8526972720787750912_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/68946234_478639022684343_4211986019580379136_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/69058530_478638969351015_827618126102265856_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/69740061_488752118339700_6792788918073819136_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/70866412_498057597409152_5504723750647496704_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/70873119_498057647409147_1343899338547396608_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/70908893_498057640742481_4364920347472953344_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/70917691_498057590742486_429949891599400960_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/70984694_488752111673034_8519681094679789568_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/71237127_502536863627892_7095444375680843776_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/71275167_502536810294564_4626722922268983296_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/71331215_502536813627897_5988399044555177984_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/73007037_524049091476669_6374424657805705216_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/73011660_525559844658927_5688430651632517120_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/73190740_525559847992260_4051017976835473408_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/73259267_524049098143335_6940011145621143552_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/73504706_525559811325597_3709831225154732032_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74158280_525559691325609_3057755065344327680_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74237609_524049211476657_3855983365895225344_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74431239_524049204809991_2419188596314472448_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74474890_524049284809983_8535224963576102912_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74674464_525559751325603_5836931847426146304_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/74893795_525559731325605_3864302653123067904_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/75125109_524049268143318_3897673836048416768_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/75252954_524049141476664_4936713260599607296_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/75398177_525559817992263_6127256526940798976_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/75594564_525559921325586_6612779472829546496_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/76705151_525559951325583_3223123375098429440_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/76921554_525559681325610_8386742974876221440_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/77125388_524049154809996_3769799078959906816_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/78756432_551599368721641_4103537077449129984_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/79190345_551599405388304_9118684695800840192_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/79486068_551599362054975_5860068548486889472_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81001369_572725943275650_1247377000906096640_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81089052_572725516609026_1411315374313439232_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81124034_572725829942328_7623223116793118720_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81437145_572725693275675_8052007526067077120_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81721505_572725529942358_8603992793053921280_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81821851_572725573275687_2456733044342521856_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81854031_572725629942348_5970999436254380032_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81886769_572725889942322_7133082183413006336_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81911620_572725896608988_1487700491499470848_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81924787_572725756609002_3385127484214214656_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81935536_572725609942350_5916046712681529344_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/81994419_572725763275668_8200594981983879168_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/82143151_572725816608996_5873648135565213696_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/82214972_572725719942339_4762769099451269120_n.jpg',
  'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/gallegry/82451736_572725563275688_4067435725547634688_n.jpg',
]
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
  },
  column: {
    flex: '25%',
    maxWidth: '25%',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      flex: '50%',
      maxWidth: '50%',
    },
  },
  imageContainer: {
    marginTop: '20px',
    width: '100%',
    borderRadius: '6px',
    cursor: 'pointer',
    overflow: 'hidden',
    boxShadow: '10px 10px 15px 0px rgba(37,53,60,0.2)',
  },
  image: {
    objectFit: 'cover',
    verticalAlign: 'middle',
    width: '100%',
    transform: 'scale(1,1)',
    transition: 'transform .3s ease',
    '&:hover': {
      transform: 'scale3d(1.5,1.5, 1.5)',
    },
  },
}))

const chunk = (arr, len) => {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

const Gallery = ({}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const handleImageClick = index => () => {
    setPhotoIndex(index)
    setIsModalOpen(true)
  }

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  })

  const handleClickPrev = useCallback(() => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length)
  })
  const handleClickNext = useCallback(() => {
    setPhotoIndex((photoIndex + 1) % images.length)
  })

  let Content

  if (!images || images.length == 0) {
    Content = <div />
  } else {
    const imagesTruncated = images.slice(0, 12)
    Content = chunk(imagesTruncated, Math.ceil(imagesTruncated.length / 4)).map(
      items => {
        return (
          <div className={classes.column}>
            {items.map((item, i) => {
              return (
                <div className={classes.imageContainer} key={item}>
                  <Img
                    className={classes.item}
                    src={item}
                    className={classes.image}
                    onClick={handleImageClick(images.indexOf(item))}
                  />
                </div>
              )
            })}
          </div>
        )
      }
    )
  }

  return (
    <React.Fragment>
      {typeof window !== 'undefined' && isModalOpen && images && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={handleCloseModal}
          onMovePrevRequest={handleClickPrev}
          onMoveNextRequest={handleClickNext}
        />
      )}
      <div className={classes.container}>{Content}</div>
    </React.Fragment>
  )
}

Gallery.defaultProps = {}

export default Gallery
