import React from 'react'
import clsx from 'clsx'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import Slider from 'react-slick'
import TeamCard from './TeamCard'
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  rootPrimary: {
    marginTop: theme.spacing(10),
  },
}))

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 2 } },
]

const Team = ({ variant }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const instructors = [
    t('equipe:equipe.b_michel', {
      returnObjects: true,
    }),
    t('equipe:equipe.b_bloquet', {
      returnObjects: true,
    }),
    t('equipe:equipe.a_berenger', {
      returnObjects: true,
    }),
    t('equipe:equipe.r_jonckhere', {
      returnObjects: true,
    }),
    t('equipe:equipe.n_maes', {
      returnObjects: true,
    }),
    t('equipe:equipe.a_rosmarin', {
      returnObjects: true,
    }),
    t('equipe:equipe.y_denis', {
      returnObjects: true,
    }),
    t('equipe:equipe.g_goudsmet', {
      returnObjects: true,
    }),
  ]

  return variant === 'secondary' ? (
    <Grid container className={classes.root}>
      <Grid item sm={3} xs={12}>
        <TeamCard
          instructor={t('equipe:equipe.c_thys', {
            returnObjects: true,
          })}
          variant={variant}
        />
      </Grid>
      <Grid item sm={9} xs={12}>
        <Slider
          dots={true}
          adaptiveHeight={false}
          slidesToShow={3}
          arrows={false}
          slidesToScroll={1}
          responsive={responsive}
          autoplay={true}
        >
          {instructors.map(instructor => (
            <TeamCard instructor={instructor} variant={variant} />
          ))}
        </Slider>
      </Grid>
    </Grid>
  ) : (
    <Grid container className={clsx(classes.root, classes.rootPrimary)}>
      <Grid item sm={3} xs={12}>
        <TeamCard
          instructor={t('equipe:equipe.c_thys', {
            returnObjects: true,
          })}
          variant={variant}
        />
      </Grid>
      {instructors.map(instructor => (
        <Grid item sm={3} xs={12}>
          <TeamCard instructor={instructor} variant={variant} />
        </Grid>
      ))}
    </Grid>
  )
}

Team.defaultProps = {
  variant: 'secondary',
}

export default Team
