import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import {
  Link as TestLink,
  Element,
  scroller,
  animateScroll,
} from 'react-scroll'
import Img from 'gatsby-image'
import Iframe from 'react-iframe'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

const offset = -10

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    //width: '100%',
    zIndex: 1,
    //outline: '8px solid ' + theme.palette.primary.main,

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  imageBackground: {
    position: 'absolute',

    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    background: '#fff',
    opacity: 1,
    borderRadius: '10px',
    zIndex: -1,

    //border: 'solid 8px ' + theme.palette.secondary.main,
  },
  imageBackground1: {
    position: 'absolute',

    top: offset * 3,
    bottom: offset * 3,
    left: offset * 3,
    right: offset * 3,
    background: '#fafdfd',
    opacity: 0.5,
    borderRadius: '10px',
    zIndex: -2,

    //border: 'solid 8px ' + theme.palette.secondary.main,
  },
}))

const ShadowWrapper = ({ children, className }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.imageBackground} />
      <div className={classes.imageBackground1} />
      {children}
    </div>
  )
}

ShadowWrapper.defaultProps = {}

export default ShadowWrapper
