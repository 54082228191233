import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
const defaultTheme = createMuiTheme({})
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1B5354',
    },
    secondary: {
      main: '#4CCBC3',
    },
    tertiary: {
      light: '#fafdfd',
      main: '#ecf7f7',
    },
    background: {
      default: '#fff',
    },
  },

  typography: {
    htmlFontSize: 18,
    fontSize: 18,
    fontFamily: "'Muli', sans-serif",
    body2: {
      fontSize: '1rem',
      lineHeight: 1.8,
    },
    h1: {
      fontFamily: "'Muli', sans-serif",
      fontWeight: 800,
      fontSize: '4rem',
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h2: {
      fontFamily: "'Muli', sans-serif",
      fontWeight: 800,
      fontSize: '3rem',
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    paragraph: {
      marginTop: defaultTheme.spacing(3),
      marginBottom: defaultTheme.spacing(3),
    },
  },

  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        color: '#fff',
      },
    },
    MuiListItem: {
      dense: {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
  },
})

export default responsiveFontSizes(theme)
