import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import MaterialButton from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import useHover from '../../hooks/useHover'

const useStyles = makeStyles(theme => ({
  root: {
    // boxShadow: '10px 10px 15px 0px rgba(37,53,60,0.1)',
    zIndex: '1',
    position: 'relative',
    padding: '20px 46px',
    marginBottom: '30px',
    textAlign: 'center',
    textTransform: 'uppercase',
    background: ({ variant }) =>
      variant === 'secondary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    color: ({ variant }) => (variant === 'secondary' ? '#d5f3f1' : '#f3fcfb'),
    fontSize: '16px',
    fontWeight: 'bold',
    outline: 'none',
    border: 'none',
    transition: 'color 0.5s',
    cursor: 'pointer',
    borderRadius: '0px',
    '&::before': {
      content: '""',
      zIndex: '1',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      borderRadius: '0px',
    },
    '&::after': {
      content: '""',
      zIndex: '-2',
      position: 'absolute',
      left: '3px',
      top: '3px',
      width: '100%',
      height: '100%',
      WebkitTransition: 'all 0.3s 0.2s',
      transition: 'all 0.3s 0.2s',
      borderRadius: '30px',
    },
  },
  rootHover: {
    color: ({ variant }) => '#fff',
    borderRadius: '0px',
    '&::after': {
      transition: 'all 0.3s',
      left: '0',
      top: '0',
      borderRadius: '30px',
    },
  },
  inner: {
    zIndex: '-1',
    overflow: 'hidden',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    borderRadius: '0px',
    background: ({ variant }) =>
      variant === 'secondary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  },
  blobs: {
    position: 'relative',
    display: 'block',
    height: '100%',
    filter: 'url("#goo")',
  },
  blob: {
    position: 'absolute',
    top: '2px',
    width: '25%',
    height: '100%',
    background: ({ variant }) =>
      variant === 'secondary'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    borderRadius: '100%',
    WebkitTransform: 'translate3d(0, 150%, 0) scale(1.7)',
    transform: 'translate3d(0, 150%, 0) scale(1.7)',
    WebkitTransition: '-webkit-transform 0.45s',
    transition: [
      '-webkit-transform 0.45s',
      'transform 0.45s',
      'transform 0.45s, -webkit-transform 0.45s',
    ],
  },
  blobHover: {
    transform: 'translateZ(0) scale(1.7)',
  },
  blob1: {
    left: '0%',
    transitionDelay: '0s',
  },
  blob2: {
    left: '30%',
    transitionDelay: '0.08s',
  },
  blob3: {
    left: '60%',
    transitionDelay: '0.16s',
  },
  blob4: {
    left: '90%',
    transitionDelay: '0.24s',
  },
  svg: { display: 'none' },
}))

const Button = props => {
  const { children, variant, className, ...other } = props
  const classes = useStyles({ variant })
  const { t, i18n } = useTranslation()
  const [hoverRef, isHovered] = useHover()

  return (
    <React.Fragment>
      <MaterialButton
        {...other}
        variant="contained"
        className={clsx(
          classes.root,
          isHovered && classes.rootHover,
          className
        )}
        ref={hoverRef}
        color={variant === 'primary' ? 'primary' : 'default'}
      >
        {children}
        <span className={classes.inner}>
          <span className={classes.blobs}>
            <span
              className={clsx(
                classes.blob,
                isHovered && classes.blobHover,
                classes.blob1
              )}
            ></span>
            <span
              className={clsx(
                classes.blob,
                isHovered && classes.blobHover,
                classes.blob2
              )}
            ></span>
            <span
              className={clsx(
                classes.blob,
                isHovered && classes.blobHover,
                classes.blob3
              )}
            ></span>
            <span
              className={clsx(
                classes.blob,
                isHovered && classes.blobHover,
                classes.blob4
              )}
            ></span>
          </span>
        </span>
      </MaterialButton>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className={classes.svg}
      >
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              result="blur"
              stdDeviation="10"
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
              result="goo"
            ></feColorMatrix>
            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
          </filter>
        </defs>
      </svg>
    </React.Fragment>
  )
}

Button.defaultProps = {
  variant: 'primary',
}

export default Button
