import React from 'react'
import clsx from 'clsx'
import GoogleMapReact from 'google-map-react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'

const offset = 30

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '500px',
    minHeight: '500px',
    width: '100%',
    zIndex: 5,
    //boxShadow: '10px 10px 48px 0px rgba(37,53,60,0.2)',
  },
  section: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    //borderRadius: '8px',
    zIndex: -1,
    border: 'solid 8px ' + theme.palette.secondary.main,
    //boxShadow: '10px 10px 48px 0px rgba(37,53,60,0.2)',
  },
  map: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  marker: {
    padding: theme.spacing(5),
    background: '#fff',
  },
}))

const Marker = ({}) => {
  const classes = useStyles()
  //return <div className={classes.marker}>hello</div>
  return <LocationOnTwoToneIcon color="primary" fontSize="large" />
}

const center = {
  lat: 50.827311,
  lng: 4.456707,
}
const zoom = 16
const Section = ({ className, children, id, bg, variant }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root)}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDbBsPrzFreCqJTaD3CM4_VUSQ8YAF73rA' }}
        defaultCenter={center}
        defaultZoom={zoom}
        className={classes.map}
      >
        <Marker {...center} ext="My Marker" />
      </GoogleMapReact>
    </div>
  )
}

export default Section
