import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'visible',
  },
  section: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  background: {
    position: 'relative',
    width: '100%',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
  },
}))

const Section = ({ className, children, id, bg, variant }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, className)}
      style={{
        background: bg,
      }}
    >
      <Container>
        <section className={classes.section} id={id}>
          {children}
        </section>
      </Container>
    </div>
  )
}

export default Section
