import React, { useState } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Link } from 'react-scroll'
import { Link as GatsbyLink } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from 'react-spring'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'

import pagesInfos from '../../config/i18n/pagesInfos'
import useHover from '../../hooks/useHover'

const OFFSET = -100

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  content: {
    position: 'absolute',
    marginTop: theme.spacing(2),
    backgroundColor: '#FAFDFD',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'block',
    borderRadius: '5px',
    zIndex: 5,
    minWidth: '300px',
  },
  contentHovered: {},
  link: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: '6px',
    fontWeight: 800,
    fontSize: '1.2em',
    textAlign: 'center',
    textDecoration: 'none',

    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    transition: 'color 0.5s ease, background-color 0.5s ease',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
  },
  linkActive: {
    color: theme.palette.secondary.main + ' !important',
  },
  zIndex: {
    zIndex: 5,
  },
  title: {
    fontWeight: 800,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&::after': {
      content: "''",
      display: 'block',
      height: '6px',
      width: '30px',
      borderRadius: '2px',
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  ulSublinks: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  liSublinks: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    display: 'block',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sublink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 700,
  },
}))

const Menu = ({ link, variant, location, onMenuClose, isMobile }) => {
  const classes = useStyles()
  const [hoverRef, isHovered] = useHover()

  const props = useSpring({
    //maxHeight: isHovered ? 1000 : 0,
    opacity: isHovered ? 1 : 0,
    visibility: isHovered ? 'visible' : 'hidden',
  })

  const Items = link.children.map(item => {
    return (
      <li key={item.name} className={classes.liSublinks}>
        <Typography color="primary" variant="subtitle2">
          <GatsbyLink
            to={item.url}
            activeClassName={classes.linkActive}
            className={classes.sublink}
          >
            {item.label}
          </GatsbyLink>
        </Typography>
      </li>
    )
  })

  const { t, i18n } = useTranslation()

  const lang = i18n.language

  return (
    <div className={classes.root} ref={!isMobile ? hoverRef : undefined}>
      {variant === 'primary' && link.section ? (
        <Link
          activeClass={classes.linkActive}
          className={classes.link}
          to={link.name}
          spy={true}
          smooth={true}
          offset={OFFSET}
        >
          {link.label}
        </Link>
      ) : (
        <GatsbyLink
          to={
            variant === 'secondary' && link.section
              ? pagesInfos.ComponentIndex.path[lang] + '#' + link.name
              : link.url
          }
          activeClassName={classes.linkActive}
          className={clsx(
            classes.link,
            link.children.length > 0 &&
              link.children.some(sublink =>
                location.pathname.includes(sublink.name)
              ) &&
              classes.linkActive
          )}
        >
          {link.label}
        </GatsbyLink>
      )}

      {link.children.length > 0 && !isMobile && (
        <animated.div style={props} className={clsx(classes.content)}>
          <ul className={classes.ulSublinks}>{Items}</ul>
        </animated.div>
      )}
      {link.children.length > 0 && isMobile && (
        <ul className={classes.ulSublinks}>{Items}</ul>
      )}
    </div>
  )
}

Menu.defaultProps = {
  link: undefined,
  isScrollLink: false,
}

export default Menu
