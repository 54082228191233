import React, { useState } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { useSpring } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  title: {
    position: 'relative',

    fontWeight: 800,
    lineHeight: 1.6,
    marginBottom: theme.spacing(5),
  },
  subtitle: {},
  uppercase: {
    textTransform: 'uppercase',
  },
  line: {
    position: 'absolute',
    height: '6px',
    width: '100px',
    bottom: -20,
    borderRadius: '2px',
    backgroundColor: theme.palette.secondary.main,
  },
  lineCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },
  textCenter: {
    textAlign: 'center',
  },
  header: {
    textTransform: 'uppercase',
    fontWeight: 800,
  },
}))

const Heading = ({ title, subtitle, uppercase, variant, header, center }) => {
  const classes = useStyles()
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  return (
    <div className={clsx(classes.root, center && classes.textCenter)}>
      {header && (
        <Typography
          variant="caption"
          gutterBottom
          color="secondary"
          className={clsx(classes.header)}
        >
          {header}
        </Typography>
      )}

      <Typography
        variant={variant === 'primary' ? 'h4' : 'h6'}
        component={variant === 'primary' ? 'h2' : 'h3'}
        gutterBottom
        className={clsx(classes.title, uppercase && classes.uppercase)}
      >
        <strong>{title}</strong>
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        className={clsx(classes.subtitle)}
      >
        {subtitle}
      </Typography>
    </div>
  )
}

Heading.defaultProps = {
  variant: 'primary',
  uppercase: true,
  header: undefined,
}

export default Heading
